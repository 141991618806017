import { IconClose } from "../../icons";
import React from "react";
// eslint-disable-next-line no-unused-vars
import style from "./Panel.scss";

export const Panel = (props) => {
  const { isOpen, className, onClose, children } = props;
  return (
    <div
      className={`panel ${isOpen ? "panel-opened " : ""} u-bg-greyDark ${
        className ? className : ""
      }`}
    >
      <section className={`panel-content  u-p-md`}>
        <button className={"icon-sm panel-close "} onClick={onClose}>
          <IconClose />
        </button>
        {children}
      </section>
    </div>
  );
};
