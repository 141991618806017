import React, { useState } from "react";
// eslint-disable-next-line no-unused-vars
import style from "./Banner.scss";

export const Banner = (props) => {
  const { openPanel } = props;
  const [privacyAccepted, setPrivacyAccepted] = useState(
    localStorage.getItem("privacyAccepted")
  );
  const onClose = () => {
    localStorage.setItem("privacyAccepted", "true");
    setPrivacyAccepted("true");
  };

  if (privacyAccepted) return null;
  return (
    <div className="banner u-p-xs">
      <p onClick={() => openPanel("policy")}>
        This website uses cookies to ensure you get the best experience on our
        website. <span className="typo-underline">Learn more</span>
      </p>
      <button className="u-mt-sm button button-full" onClick={onClose}>
        Got it!
      </button>
    </div>
  );
};
