import React from "react";
// eslint-disable-next-line no-unused-vars
import style from "./Loader.scss";

export const Loader = () => {
  return (
    <div className="loader">
      <img src="/loader.gif" alt="loading..." />
    </div>
  );
};
