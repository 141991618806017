import React from "react";

export const IconBookmarksFilled = (props) => {
  const { number } = props;
  return (
    <div {...props}>
      <svg fill="none">
        <path
          d="M18 18L16 17V4C16 2.9 15.1 2 14 2H4C4 0.9 4.89 0 5.99 0H16C17.1 0 18 0.9 18 2V18ZM2 4H12C13.1 4 14 4.9 14 6V22L7 19L0 22V6C0 4.9 0.9 4 2 4Z"
          fill="#262727"
        />
      </svg>

      {number !== 0 && <div className="icon-number">{number}</div>}
    </div>
  );
};
