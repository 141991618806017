import React from "react";
import {
  Page,
  Font,
  Text,
  Link,
  Image,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
// eslint-disable-next-line no-unused-vars
import style from "./Pdf.scss";

// Register font
Font.register({
  family: "TitlingGothicFBWide-Thin",
  fonts: [
    { src: "/fonts/TitlingGothicFBWide-Thin.otf" }, // font-style: normal, font-weight: normal
  ],
});

// Register font
Font.register({
  family: "TitlingGothicFBWide-Regular",
  fonts: [
    { src: "/fonts/TitlingGothicFBWide-Regular.otf" }, // font-style: normal, font-weight: normal
  ],
});

// Create Document Component
export const Pdf = (props) => {
  const { selectedColors } = props;
  const percent = 100 / selectedColors.length;
  // Create styles
  const styles = StyleSheet.create({
    page: {
      fontFamily: "TitlingGothicFBWide-Thin",
      flexDirection: "col",
      backgroundColor: "#262727",
      color: "#FFFFFF",
      width: "100%",
    },
    section: {
      padding: 20,
      fontSize: 12,
    },
    sectionSpaceBetween: {
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: 12,
    },
    row: {
      flexDirection: "row",
      flexWrap: "nowrap",
      fontSize: 8,
      width: "100%",
    },
    logo: {
      width: 300,
    },
    rowChild: {
      flex: "1 1 0pt",
      flexBasis: `${percent}%`,
      maxWidth: `${percent}%`,
      minWidth: `${percent}%`,
      fontWeight: "bold",
      boxSizing: "border-box",
      padding: "10pt 10pt 0pt 10pt",
    },
    rowLine: {
      flexDirection: "row",
      flexWrap: "nowrap",
      border: "solid 2pt #ffffff",
      width: "100%",
      fontSize: 9,
      paddingBottom: 10,
    },
    rowTitle: {
      flex: "1 1 0pt",
      flexBasis: `${percent}%`,
      maxWidth: `${percent}%`,
      minWidth: `${percent}%`,
      boxSizing: "border-box",
      padding: "10pt 10pt 0pt 10pt",
    },
  });
  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape">
        <View style={{ ...styles.section, ...styles.sectionSpaceBetween }}>
          <Image style={styles.logo} src="/color-selector.png " />
          <Text>Design by Eugénie Garcia</Text>
        </View>
        <View style={styles.section}>
          <Text>Your palette</Text>
        </View>
        <View style={styles.row}>
          {selectedColors.map((color, index) => (
            <Text
              style={{
                backgroundColor: color.hex,
                height: "40pt",
                flexGrow: 1,
              }}
              key={`color1-${index}`}
            >
              &nbsp;
            </Text>
          ))}
        </View>
        <View style={styles.rowLine}>
          {selectedColors.map((color, index) => (
            <Text style={styles.rowTitle} key={`name-${index}`}>
              {color.title}
            </Text>
          ))}
        </View>
        <View style={styles.row}>
          {selectedColors.map((color, index) => (
            <Text style={styles.rowTitle} key={`hex-title-${index}`}>
              HEX
            </Text>
          ))}
        </View>
        <View style={styles.rowLine}>
          {selectedColors.map((color, index) => (
            <Text
              style={{ ...styles.rowChild, textTransform: "uppercase" }}
              key={`hex-${index}`}
            >
              {color.hex}
            </Text>
          ))}
        </View>

        <View style={styles.row}>
          {selectedColors.map((color, index) => (
            <Text style={styles.rowTitle} key={`rgb-title-${index}`}>
              RGB
            </Text>
          ))}
        </View>
        <View style={styles.rowLine}>
          {selectedColors.map((color, index) => (
            <Text style={styles.rowChild} key={`rgb-${index}`}>
              {color.r}, {color.g}, {color.b}
            </Text>
          ))}
        </View>

        <View style={styles.row}>
          {selectedColors.map((color, index) => (
            <Text style={styles.rowTitle} key={`cmyk-title-${index}`}>
              CMYK
            </Text>
          ))}
        </View>
        <View style={styles.rowLine}>
          {selectedColors.map((color, index) => (
            <Text style={styles.rowChild} key={`cmyk-${index}`}>
              {color.c}, {color.m}, {color.y},{color.k}
            </Text>
          ))}
        </View>
        <View style={styles.row}>
          {selectedColors.map((color, index) => (
            <Text
              style={{
                backgroundColor: color.hex,
                height: "40pt",
                flexGrow: 1,
              }}
              key={`color1-${index}`}
            >
              &nbsp;
            </Text>
          ))}
        </View>
        <View
          style={{
            ...styles.section,
            ...styles.sectionSpaceBetween,
            marginTop: 40,
            paddingBottom: 0,
          }}
        >
          <Text>Say hi &amp; tell me your feedback</Text>
          <Link src="www.color-selector.com">www.color-selector.com </Link>
        </View>
        <View style={{ ...styles.section, ...styles.sectionSpaceBetween }}>
          <Text>
            at{" "}
            <Link src="mailto:hello.igy@color-selector.com">
              hello.igy@color-selector.com
            </Link>
          </Text>
          <Text>
            visit us on INSTAGRAM{" "}
            <Link src="https://www.instagram.com/colorselector_igy">
              @colorSelector_igy
            </Link>
          </Text>
        </View>
      </Page>
    </Document>
  );
};
