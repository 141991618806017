export const hexColor = (hex) => {
  if (!hex) return hex;
  if (hex.includes("#")) return hex;
  return `#${hex}`;
};

export const filterColors = (order, colors, callback) => {
  let sorted = [];
  switch (order) {
    case "alphabetical":
      sorted = [...colors].sort((a, b) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
        if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
        return 0;
      });
      break;
    case "tint":
      sorted = [...colors].sort((a, b) => {
        if (a.hex.toLowerCase() < b.hex.toLowerCase()) return -1;
        if (a.hex.toLowerCase() > b.hex.toLowerCase()) return 1;
        return 0;
      });
      break;
    default:
    case "classic":
      sorted = [...colors].sort((a, b) => {
        if (a.index < b.index) return -1;
        if (a.index > b.index) return 1;
        return 0;
      });
      break;
  }
  callback(sorted);
};
