import React from "react";
// eslint-disable-next-line no-unused-vars
import style from "./ColorPreview.scss";
import { Selector } from "..";

export const ColorPreview = (props) => {
  const { color } = props;
  return (
    <div className="flex-spaceArround color-preview u-pb-md">
      <div
        className="color-preview-box"
        style={{ backgroundColor: color.hex }}
      ></div>
      <div className="childGrow u-px-md">
        <h4 className="u-mb-xs">{color.title}</h4>
        <p className="h4 typo-uppercase">{color.hex}</p>
      </div>
      <div className="u-pr_sm-sm">
        <Selector hex={color.hex} />
      </div>
    </div>
  );
};
