import React from "react";

export const IconArrow = (props) => {
  return (
    <div {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
