export const deconstructUrl = (query) => {
  const selectedColorsUrl = query.get("selected");
  let selectedColors = [];
  const currentColor = query.get("currentColor");
  const currentPanel = query.get("currentPanel");
  if (selectedColorsUrl) {
    const transformedSelected = "#" + selectedColorsUrl.replaceAll(",", ",#");
    selectedColors = transformedSelected.split(",");
  }
  return {
    selectedColors,
    currentColor,
    currentPanel,
  };
};

const constructParameter = (key, value, needSeparator) => {
  if (!value) return "";
  return `${needSeparator ? "&" : ""}${key}=${value}`;
};

export const constructUrl = ({
  selectedColors,
  currentPanel,
  currentColor,
}) => {
  const selected = selectedColors.join(",").replaceAll("#", "");

  const currentColorString = constructParameter(
    "currentColor",
    currentColor,
    Boolean(selected)
  );
  const currentPanelString = constructParameter(
    "currentPanel",
    currentPanel,
    Boolean(selected)
  );

  const url = `?${
    selected ? "selected=" + selected : ""
  }${currentColorString}${currentPanelString}`;
  return url;
};
