import React from "react";
import { RichText, SmallContent } from "..";

export const About = (props) => {
  const { title, description, iconText, textText } = props;
  return (
    <>
      <h1 className="u-color-beige u-mb-sm">{title}</h1>
      <SmallContent>
        <RichText content={description} />
      </SmallContent>
      <div className="u-mt-lg">
        {iconText &&
          iconText.map((iconTextContent, index) => (
            <div className="flex-alignCenter" key={index}>
              {iconTextContent.icon ? (
                <>
                  <img className="u-p-sm" src={iconTextContent.icon} alt="" />
                  <RichText
                    className=" u-p-sm"
                    content={iconTextContent.text}
                  />
                </>
              ) : (
                <SmallContent>
                  <RichText
                    className="u-py-lg"
                    content={iconTextContent.description}
                  />
                </SmallContent>
              )}
            </div>
          ))}

        <div className="flex-spaceBetween u-pt-lg">
          {textText &&
            textText.map((textTextContent, index) => (
              <div key={`text-text-${index}`}>
                <RichText
                  className="captionBody"
                  content={textTextContent.description}
                />
                <RichText
                  className="captionBody u-pb-sm"
                  content={textTextContent.text}
                />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};
