import React, { useState } from "react";
import { ColorPreview } from "..";
// eslint-disable-next-line no-unused-vars
import style from "./Index.scss";
import { IconSquareCheck, IconSquareRegular } from "../../icons";
import { useMediaQuery } from "react-responsive";
import { filterColors } from "../../utils/colors";

const Filter = (props) => {
  const { title, active, onClick } = props;
  return (
    <button
      className={`bodyFilter filter-btn u-p-sm flex-spaceAround`}
      onClick={onClick}
    >
      <div className="u-pr-sm">
        {active ? (
          <IconSquareCheck className="icon icon-xs " />
        ) : (
          <IconSquareRegular className="icon icon-xs " />
        )}
      </div>
      {title}
    </button>
  );
};

export const Index = (props) => {
  const { colors, filterAction, title } = props;
  const [filter, setFilter] = useState("classic");
  const isMobile = useMediaQuery({
    query: "(max-device-width: 1024px)",
  });

  return (
    <>
      <h1 className="u-color-beige u-mb-sm">{title}</h1>
      <div className="bodyFilter filters flex-spaceAround u-my-md">
        <p className="u-p-sm">Sort By</p>
        {isMobile ? (
          <div>
            <Filter
              active={filter === "classic"}
              title="classic"
              onClick={() => {
                setFilter("classic");
                filterColors("classic", colors, filterAction);
              }}
            />
            <Filter
              active={filter === "tint"}
              title="tint"
              onClick={() => {
                setFilter("tint");
                filterColors("tint", colors, filterAction);
              }}
            />
            <Filter
              active={filter === "alphabetical"}
              title="alphabetical"
              onClick={() => {
                setFilter("alphabetical");
                filterColors("alphabetical", colors, filterAction);
              }}
            />
          </div>
        ) : (
          <>
            <Filter
              active={filter === "classic"}
              title="classic"
              onClick={() => {
                setFilter("classic");
                filterColors("classic", colors, filterAction);
              }}
            />
            <Filter
              active={filter === "tint"}
              title="tint"
              onClick={() => {
                setFilter("tint");
                filterColors("tint", colors, filterAction);
              }}
            />
            <Filter
              active={filter === "alphabetical"}
              title="alphabetical"
              onClick={() => {
                setFilter("alphabetical");
                filterColors("alphabetical", colors, filterAction);
              }}
            />
          </>
        )}
      </div>
      <div className="flex-flexWrap index-container">
        {colors &&
          colors.map((color, index) => (
            <ColorPreview color={color} key={`Index-Color-${index}`} />
          ))}
      </div>
    </>
  );
};
