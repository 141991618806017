import React, { useState, useEffect } from "react";
import { SmallContent } from "..";
import { ExtendedLink } from "..";
import { IconFacebook, IconInsta, IconMail } from "../../icons";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const CustomForm = ({ status, message, onValidated }) => {
  let email;
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn")
  );

  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    });

  useEffect(() => {
    if (isLoggedIn) return;
    if (status === "success" || status === "error") {
      localStorage.setItem("isLoggedIn", "true");
      setIsLoggedIn("true");
    }
  }, [status, isLoggedIn]);

  return (
    <div className={`status-${status} ${isLoggedIn ? "status-loggedIn" : ""}`}>
      {(status === "success" || status === "error" || isLoggedIn) && (
        <p className="selected-form-field">
          You are subscribed to our newsletter :)
        </p>
      )}
      <div className="selected-form flex">
        <input
          ref={(node) => (email = node)}
          type="email"
          className="selected-form-field"
          placeholder="Enter your email address"
        />
        <button className="button" onClick={submit}>
          Sign up !
        </button>
      </div>
    </div>
  );
};

export const Newsletter = (props) => {
  const { openPanel } = props;

  return (
    <div className="flex-fullHeight-flexWrap-alignBottom">
      <div>
        <h1 className="u-color-beige u-mb-md">
          <img src="/color-selector.svg" alt={"Color Selector"} />
        </h1>
        <SmallContent>
          <div className="u-mb-md">
            <h2 className="captionTitle u-mb-xs">
              Get monthly color trend insights
            </h2>
            <p>
              Sign up to receive the monthly color trend summary from Color
              Selector, fashion, architecture, design, photography and more,
              straight to your inbox.
            </p>

            <div className="u-my-md">
              <MailchimpSubscribe
                url="https://color-selector.us7.list-manage.com/subscribe/post?u=386db4f637493d72aeae10a6f&amp;id=1a9f585ee3"
                render={({ subscribe, status, message }) => (
                  <div>
                    <CustomForm
                      status={status}
                      message={message}
                      onValidated={(formData) => subscribe(formData)}
                    />
                  </div>
                )}
              />
            </div>
          </div>
          <div className="u-mb-md">
            <h2 className="captionTitle u-mb-xs">Stay connected </h2>
            <p>Visit our free color content stream</p>
            <div className="flex">
              <a
                className="u-py-xs u-pr-xs"
                href="https://www.facebook.com/colorselector.igy"
                target="blank"
              >
                <IconFacebook />
              </a>
              <a
                className="u-py-xs u-pr-xs"
                href="https://www.instagram.com/colorselector_igy"
                target="blank"
              >
                <IconInsta />
              </a>
              <a
                className="u-py-xs u-pr-xs"
                href="mailto:hello.igy@color-selector.com"
                target="blank"
              >
                <IconMail />
              </a>
            </div>
          </div>
        </SmallContent>
        <div>
          <ExtendedLink
            onClick={() => openPanel("policy")}
            className={"typo-underline"}
          >
            privacy policy
          </ExtendedLink>
        </div>
        <div>
          <ExtendedLink
            onClick={() => openPanel("cookie")}
            className={`typo-underline`}
          >
            cookie policy
          </ExtendedLink>
        </div>
      </div>
    </div>
  );
};
