import { Swiper, SwiperSlide } from "swiper/react";
import React, { useEffect, useState } from "react";
import { Color, Home, Intro, Newsletter } from "../";
import SwiperCore, { Controller } from "swiper";
import "swiper/swiper.scss";
import { useMediaQuery } from "react-responsive";
// eslint-disable-next-line no-unused-vars
import styles from "./Slider.scss";

SwiperCore.use([Controller]);

export const Slider = (props) => {
  const { content, colors, openPanel, backToStart, onBackToStartDone } = props;
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1024px)",
  });
  const [controlledSwiper, setControlledSwiper] = useState(null);

  useEffect(() => {
    if (!backToStart) return;
    if (isDesktopOrLaptop) {
      if (controlledSwiper) {
        controlledSwiper.slideTo(0);
        onBackToStartDone(false);
      }
    } else {
      window.scrollTo(0, 0);
      onBackToStartDone(false);
    }
  }, [backToStart, isDesktopOrLaptop, controlledSwiper, onBackToStartDone]);
  return isDesktopOrLaptop ? (
    <Swiper
      slidesPerView="auto"
      //centeredSlides="true"
      grabCursor="true"
      className="horizontal-wrapper"
      onSwiper={setControlledSwiper}
      controller={{ control: controlledSwiper }}
      keyboard={true}
      shortSwipes={false}
    >
      <SwiperSlide virtualIndex={0}>
        <Intro hideClose="true">
          <Home {...content.home} />
        </Intro>
      </SwiperSlide>

      {colors &&
        colors.map((color, index) => (
          <SwiperSlide
            key={`slide-color-${index + 1}`}
            virtualIndex={index + 1}
          >
            <Color
              slideTo={() => {
                if (controlledSwiper) {
                  controlledSwiper.slideTo(index + 1);
                  setTimeout(() => {
                    controlledSwiper.update();
                  }, 1000);
                }
              }}
              onClose={() => {
                setTimeout(() => {
                  controlledSwiper.update();
                }, 1000);
              }}
              {...color}
            />
          </SwiperSlide>
        ))}
      <SwiperSlide virtualIndex={colors.length + 1}>
        <Intro hideClose="true">
          <Newsletter openPanel={openPanel} />
        </Intro>
      </SwiperSlide>
    </Swiper>
  ) : (
    <>
      <Intro hideClose="true">
        <Home {...content.home} />
      </Intro>
      {colors &&
        colors.map((color, index) => (
          <Color key={`color${index + 1}`} {...color} />
        ))}
      <Intro hideClose="true">
        <Newsletter openPanel={openPanel} />
      </Intro>
    </>
  );
};
