import React from "react";
import { RichText, SmallContent } from "..";
import { IconArrow } from "../../icons";
import { useMediaQuery } from "react-responsive";

export const Home = (props) => {
  const { title, description, legal } = props;

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1024px)",
  });

  return (
    <div className="flex-fullHeight-flexWrap-alignTop">
      <div>
        <h1 className="u-color-beige u-mb-sm">
          <img src="/color-selector.svg" alt={title} />
        </h1>
        <SmallContent className="u-mb-sm">
          <RichText content={description} />
        </SmallContent>
      </div>
      <div className="align-self-btm flex-spaceBetween-grow u-mb-xs">
        <RichText content={legal} />

        {isDesktopOrLaptop && (
          <h3 className="flex-alignCenter">
            drag to discover
            <IconArrow className="u-pl-sm" />
          </h3>
        )}
      </div>
    </div>
  );
};
