import { IconBookmarks, IconBookmarksSingleFilled } from "../../icons";
import React from "react";
import { AppContext } from "../AppContext/AppContext";

export const Selector = (props) => {
  const { hex } = props;
  return (
    <AppContext.Consumer>
      {({ selectedColors }) =>
        selectedColors.list.find((color) => color === hex) ? (
          <IconBookmarksSingleFilled
            className={`icon`}
            onClick={(e) => {
              e.stopPropagation();
              selectedColors.remove(hex);
            }}
          />
        ) : (
          <IconBookmarks
            className={`icon `}
            onClick={(e) => {
              e.stopPropagation();
              selectedColors.add(hex);
            }}
          />
        )
      }
    </AppContext.Consumer>
  );
};
