import React from "react";
// eslint-disable-next-line no-unused-vars
import style from "./SelectedInfo.scss";
import { Selector } from "../Selector/Selector";

const TextValue = (props) => {
  const { title, value } = props;

  return (
    <div className="u-p-md border-bottom relative">
      <h3 className="captionDescription">{title}</h3>
      <p className="no-copy captionTitle typo-uppercase">{value}</p>
    </div>
  );
};
export const SelectedInfo = (props) => {
  const { color } = props;

  return (
    <div
      className={`selected-info ${color.darkText ? "dark" : ""}`}
      style={{ backgroundColor: color.hex }}
    >
      <div className="u-p-md border-bottom">
        <div className="flex-alignCenter-spaceBetween">
          <h3>{color.title}</h3>
          <Selector hex={color.hex} />
        </div>
      </div>
      <TextValue title="HEX" value={color.hex} />
      <TextValue title="RGB" value={`${color.r}, ${color.g}, ${color.b}`} />
      <TextValue
        title="CMYK"
        value={`${color.c}, ${color.m}, ${color.y}, ${color.k}`}
      />
    </div>
  );
};
