import React from "react";
import { RichText } from "..";

export const Policy = (props) => {
  const { title, content } = props;
  return (
    <>
      <h1 className="u-color-beige u-mb-sm">{title}</h1>

      <div className="u-mt-lg">
        {content &&
          content.map((titleTextContent, index) => (
            <div className="u-mb-lg" key={index}>
              <h2 className="u-pb-sm">{titleTextContent.title}</h2>
              <RichText content={titleTextContent.text} />
            </div>
          ))}
      </div>
    </>
  );
};
