import React from "react";
import { IconClose } from "../../icons";
// eslint-disable-next-line no-unused-vars
import style from "./Intro.scss";
import { ExtendedLink } from "..";

export const Intro = (props) => {
  const { hideClose, children } = props;
  return (
    <div className="intro horizontal-child-scrollable horizontal-child u-bg-greyDark u-p-md  ">
      {!hideClose && (
        <ExtendedLink to="/" className="back-link">
          <IconClose />
        </ExtendedLink>
      )}
      {children}
    </div>
  );
};
