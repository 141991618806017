import React from "react";

export const IconBookmarksSingleFilled = (props) => {
  return (
    <div {...props}>
      <svg viewBox="0 0 24 24" fill="none">
        <path
          d="M17 3H7C5.9 3 5 3.9 5 5V21L12 18L19 21V5C19 3.9 18.1 3 17 3Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
