import React from "react";

export const IconMail = (props) => {
  return (
    <div {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 0C5.3726 0 0 5.3726 0 12C0 18.6274 5.3726 24 12 24C18.6274 24 24 18.6274 24 12C24 5.3726 18.6274 0 12 0ZM5 11.9802C5 8.1313 8.1401 5 11.9999 5C15.8597 5 18.9998 8.1313 18.9998 11.9802C18.9998 12.5254 18.9543 12.9668 18.8524 13.4099L18.8509 13.4172C18.8507 13.4185 18.8001 13.6228 18.7721 13.7119C18.5991 14.2636 18.2859 14.7231 17.8662 15.0407C17.4593 15.3488 16.9547 15.5185 16.4455 15.5185C16.3826 15.5185 16.3193 15.516 16.2572 15.5111C15.5341 15.4531 14.8983 15.0737 14.5084 14.4686C13.8378 15.141 12.9482 15.5109 11.9999 15.5109C10.0476 15.5109 8.4592 13.927 8.4592 11.9802C8.4592 10.0333 10.0476 8.44945 11.9999 8.44945C13.9522 8.44945 15.5406 10.0333 15.5406 11.9802V13.1183C15.5435 13.7824 15.9904 14.0539 16.3722 14.0845C16.7521 14.1138 17.2576 13.8932 17.4337 13.173C17.5205 12.7781 17.5647 12.3766 17.5647 11.9802C17.5647 8.92035 15.0683 6.43105 11.9999 6.43105C8.93145 6.43105 6.43515 8.92035 6.43515 11.9802C6.43515 15.0399 8.93145 17.5292 11.9999 17.5292C13.068 17.5292 14.1068 17.225 15.004 16.6495L15.0201 16.6392L15.963 17.7325L15.9428 17.7463C14.7774 18.5406 13.414 18.9604 11.9999 18.9604C8.1401 18.9604 5 15.8291 5 11.9802Z"
          fill="#F5F5E9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1053 11.9802C14.1053 13.1379 13.1607 14.0798 11.9997 14.0798C10.8387 14.0798 9.89417 13.1379 9.89417 11.9802C9.89417 10.8225 10.8387 9.88062 11.9997 9.88062C13.1607 9.88062 14.1053 10.8225 14.1053 11.9802Z"
          fill="#F5F5E9"
        />
      </svg>
    </div>
  );
};
